import './Games.css';

import GassLogoBorder from '../../imgs/games/gass_logo_border.png';
import GassLogo from '../../imgs/games/gass_logo.png';
import GassPreview from '../../imgs/games/gass_preview.png';

const Games = () => {
    return (<div className="games">
        <div className="gass">
            <div className="game-banner-container">
                <div className="game-banner">
                    <a href="https://store.steampowered.com/app/2726030">
                        <img alt="don't touch me" className="logo-border" src={GassLogoBorder} />
                        <img alt="Logo" className="logo" src={GassLogo} />
                        <img alt="preview" className="preview" src={GassPreview} />
                    </a>
                </div>
            </div>

            <iframe title="steam-widget" className="steam-widget" src="https://store.steampowered.com/widget/2726030/?utm_source=homepage" />
            <div className="steam-widget-replacement">
                <div className="content">
                    <a href="https://store.steampowered.com/app/2726030/?utm_source=homepage">
                        <h1>Wishlist now!</h1>
                    </a>
                </div>
            </div>

            <div className="text-block">
                <div className="text">
                    Grapplers and Space Stuff is a <em>2-player co-op</em> game with a focus on teamwork and fun. Each player receives a brand new spaceship to control.
                </div>
                <div className="filler" />
            </div>

            <div className="text-block">
                <div className="filler" />
                <div className="text">
                    <h1>What's the hook?</h1>
                    Well, the <em>grapple hook</em>. All our spaceships are bundled with it without any extra fee. And who doesn't like grapple hooks? You can grab stuff with them. Or you can grab each other and be that stuff. All you could dream of.
                </div>
            </div>

            <div className="text-block">
                <div className="text">
                    <h1>Grab your friend</h1>
                    Already been mentioned, but let's focus on that one more time. It's a two-player <em>online co-op</em> game. You can play online. In coop. With a friend.
                </div>
                <div className="filler" />
            </div>
        </div>
    </div>);
}

export default Games;
