import React, { useState, useEffect } from "react";
import frogImage from '../../imgs/frog.png';

import './Frog.css';

const Frog = ({className, defaultVisible, minY, maxY}) => {
    const [isVisible, setIsVisible] = useState(defaultVisible);

    useEffect(() => {
        const onScroll = () => {
            const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
            const scroll = (window.scrollY / scrollableHeight) * 100;

            setIsVisible(scroll < maxY && scroll >= minY);
        };

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [minY, maxY]);

    return (
        <div className={`frog ${className} ${isVisible ? 'visible' : 'hidden'}`}>
            <img src={frogImage} alt="frog" />
        </div>
    );
}

export default Frog;