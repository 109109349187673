import './App.css';
import Frog from '../Frog/Frog';
import Games from '../Games/Games';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Logo from '../../imgs/logo.svg';

import Circles from '../../imgs/circles.png';
import Ribbit from '../../sfx/ribbit.mp3';

function App() {
  return (
    <div className="app">
      <Frog className="top-frog" defaultVisible={true} minY={0} maxY={10} />
      <Frog className="left-frog" defaultVisible={false} minY={10} maxY={50} />
      <Frog className="right-frog" defaultVisible={false} minY={50} maxY={90} />
      <Frog className="bottom-frog" defaultVisible={false} minY={90} maxY={101} />

      <img className="bg-circles" src={Circles} alt="some hidden gems" />
      <img className="kyogurtah-logo" src={Logo} alt="Logo" />

      <div className="content">
        <Header />
        <Games />
        <Footer />
      </div>

      <audio id="ribbit-audio" src={Ribbit} />
    </div>
  );
}

export default App;
