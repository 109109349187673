import './Header.css';

import Twitter from '../../imgs/icons/twitter.svg';
import Steam from '../../imgs/icons/steam.svg';
import Itch from '../../imgs/icons/itch.svg';
import Tg from '../../imgs/icons/tg.svg';
import Discord from '../../imgs/icons/discord.svg';

const links = [
    {
        image: Steam,
        link: 'https://store.steampowered.com/developer/kyogurtah',
        hint: 'Steam page'
    },
    {
        image: Twitter,
        link: 'https://twitter.com/kyogurtah',
        hint: 'Twiter page'
    },
    {
        image: Itch,
        link: 'https://kyogurtah.itch.io/',
        hint: 'Itch.io page'
    },
    {
        image: Discord,
        link: 'https://discord.gg/dzmPQvwPFd',
        hint: 'Discord Server'
    },
    {
        image: Tg,
        link: 'https://t.me/kyogurtah',
        hint: 'Telegram channel'
    },
];

const Header = () => {
    return (<div className="header">
        <div className="links">
            <p className="ribbit" onClick={() => document.getElementById('ribbit-audio').play()}>"Ribbit." 🔊</p>

            <div className="links-list">
                {
                    links.map(({ image, link, hint }) => (<a key={link} href={link}>
                        <img alt={hint} src={image} />
                    </a>))
                }
            </div>
        </div>
    </div>);
};

export default Header;