import './Footer.css';

const Footer = () => {
    return (<div className="footer">
        <p><b>2023</b></p>
        <p><b>kyogurtah</b></p>
        <p onClick={() => document.getElementById('ribbit-audio').play()}><b>(ribbit)</b></p>
    </div>);
};

export default Footer;